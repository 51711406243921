exports.components = {
  "component---customers-immomentor-template-404-tsx": () => import("./../../../customers/immomentor/template/404.tsx" /* webpackChunkName: "component---customers-immomentor-template-404-tsx" */),
  "component---customers-immomentor-template-feed-back-tmpl-tsx": () => import("./../../../customers/immomentor/template/feed-back-tmpl.tsx" /* webpackChunkName: "component---customers-immomentor-template-feed-back-tmpl-tsx" */),
  "component---customers-immomentor-template-ownerlogin-tmpl-tsx": () => import("./../../../customers/immomentor/template/ownerlogin-tmpl.tsx" /* webpackChunkName: "component---customers-immomentor-template-ownerlogin-tmpl-tsx" */),
  "component---customers-immomentor-template-page-preview-tsx": () => import("./../../../customers/immomentor/template/page-preview.tsx" /* webpackChunkName: "component---customers-immomentor-template-page-preview-tsx" */),
  "component---customers-immomentor-template-page-tmpl-tsx": () => import("./../../../customers/immomentor/template/page-tmpl.tsx" /* webpackChunkName: "component---customers-immomentor-template-page-tmpl-tsx" */),
  "component---customers-immomentor-template-project-redirect-page-tmpl-tsx": () => import("./../../../customers/immomentor/template/project-redirect-page-tmpl.tsx" /* webpackChunkName: "component---customers-immomentor-template-project-redirect-page-tmpl-tsx" */),
  "component---customers-immomentor-template-property-redirect-page-tmpl-tsx": () => import("./../../../customers/immomentor/template/property-redirect-page-tmpl.tsx" /* webpackChunkName: "component---customers-immomentor-template-property-redirect-page-tmpl-tsx" */)
}

