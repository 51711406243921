import { ISettingState, Constants, SettingActions } from './types';
const setting = require('../../src/data/setting.json');

const language = setting.find((item: any) => item.name === 'language');
const logo = setting.find((item: any) => item.name === 'logo');

const init: ISettingState = {
    list: [],
    language: language.params,
    logo: logo.params.image,
    isMobile: true,
    isDesktop: false,
    isTablet: false,
    topBarShow: true,
};

export function SettingReducer(state: ISettingState = init, action: SettingActions): ISettingState {
    switch (action.type) {
        case Constants.FETCH_ALL:
            return { ...state, list: action.payload.setting };
        case Constants.FETCH_LANGUAGE:
            return { ...state, language: action.payload.language };
        case Constants.FETCH_LOGO:
            return { ...state, logo: action.payload.logo };
        case Constants.FETCH_DEVICE:
            return { ...state, isDesktop: action.payload.isDesktop, isMobile: action.payload.isMobile, isTablet: action.payload.isTablet };
        case Constants.FETCH_TOPBARSHOW:
            return { ...state, topBarShow: action.payload.topBarShow };
        default:
            return state;
    }
}
