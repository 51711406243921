import { ActionType } from 'typesafe-actions';
import * as actions from './actions';

export type SettingActions = ActionType<typeof actions>;

export interface ISettingState {
    list: [];
    language: {
        languageUse: [{
            title: string;
            ID: number;
            code: string;
            use: boolean;
        }] | [];
        languageDefault: string;
    } | null;
    logo: any;
    isMobile: boolean;
    isDesktop: boolean;
    isTablet: boolean;
    topBarShow: boolean;
}

export enum Constants {
    FETCH_LOGO = 'FETCH_LOGO',
    FETCH_LANGUAGE = 'FETCH_LANGUAGE',
    FETCH_ALL = 'FETCH_ALL',
    FETCH_DEVICE = 'FETCH_DEVICE',
    FETCH_TOPBARSHOW = 'FETCH_TOPBARSHOW'
}
