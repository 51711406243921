import { combineReducers, createStore } from 'redux';
import { demoReducer } from './demo/reducer';
import { SettingReducer } from './setting/reducer';
import { IDemoState } from './demo/types';
import { ISettingState } from './setting/types';

export interface IRootState {
    demo: IDemoState;
    setting: ISettingState;
}

const store = createStore<IRootState, any, any, any>(
    combineReducers({
        demo: demoReducer,
        setting: SettingReducer
    })
);

export default store;
